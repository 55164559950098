import React, { useState } from 'react'
import LocationDetailsCard from './LocationDetailsCard'
import { contentAreaDataMapper } from 'Helpers/transform'
import { haversineDistance, getLatLng } from '.'
import sortBy from 'lodash/sortBy'

const CarouselRenderer = ({ data, name, center, inEditMode }) => {
  if ((!data || data.length === 0) && !inEditMode) return null

  const [isLoadMoreClicked, setLoadMoreClickStatus] = useState(false)
  const blockData = contentAreaDataMapper(data)
  const loadMoreLocationData = () => {
    setLoadMoreClickStatus(true)
  }

  const getLocationDetailCardsData = () => {
    const sortedData = sortBy(data, (a) => {
      const { Coordinates } = a
      return haversineDistance(getLatLng(Coordinates), center)
    })
    const size = isLoadMoreClicked ? sortedData.length : 1
    return sortedData.slice(0, size)
  }

  return (
    <div data-epi-edit={name}>
      <div className=" items-center">
        <div className="md:py-2 w-full">
          {getLocationDetailCardsData().map((block, i) => {
            const { ContentLink } = block
            const { Id } = ContentLink
            return (
              <div data-epi-block-id={Id} key={i}>
                <div className={`location-card-container ${!(i === 0) ? '' : 'shadow-active'}`}>
                  <LocationDetailsCard data={blockData[Id]} className="shadow-none" center={center} />
                </div>
              </div>
            )
          })}
        </div>
        <div className={`grid justify-items-center lg:my-3 md:my-2 ${(data.length < 2) ? 'hidden' : ''}`}>
          <button onClick={loadMoreLocationData}
            className={`rounded-full font-medium font-body whitespace-nowrap border-2 border-violet hover:border-violet-light text-violet hover:text-violet-light bg-white text-sm py-1 px-6 md:text-base md:py-2 md:px-8 ${isLoadMoreClicked ? 'hidden' : ''}`}>
            Load More
          </button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CarouselRenderer)
