import React, { } from 'react'

import { haversineDistance, getLatLng } from '.'
import AdressIconn from 'SVGs/AdressIcon.svg'
import ContactIcon from 'SVGs/contact-center.svg'

const LocationDetailsCard = ({ data, center }) => {
  const { Name, Address, Zipcode, Coordinates, PhoneNumber, OpeningHours, DrivingHours } = data
  const distance = haversineDistance(center, getLatLng(Coordinates))

  const OpenGoogleMaps = () => {
    window.open(
      `http://www.google.com/maps/dir//${Coordinates}`
    )
  }
  const OpenBookanAppointment = () => {
    window.open(
      'https://oac.fmsiportal.com/AppointmentWidget/service?urlCode=881MCPCZ36JFHN5UZKOC'
    )
  }
  return (
    <div className={' cursor-pointer shadow-normal transition-opacity rounded-lg md:my-0 my-4 '} >
      <div className="">
          <div className="group xl:p-5 md:p-4 p-2 grid md:justify-items-center md:items-center lg:my-5 md:my-3 md:mx-0 mx-6">
            <div className="md:flex xl:gap-x-12 lg:gap-x-4 md:gap-x-3">
              <div className="">
                <h2 data-epi-edit="SolutionCenterTitle" className="xl:w-52 lg:w-44 md:w-40 font-display font-bold xl:text-2xl md:text-xl text-lg md:my-0 sm:mt-4 mt-3">
                  {Name}
                </h2>
                <h4 data-epi-edit="Miles" className="font-display font-Semibold lg:text-sm md:text-xs text-xs">
                {Math.round(distance)} mile{Math.round(distance) > 1 ? 's' : ''} away
                </h4>
                <div className="sm:mt-3 mt-2">
                  <button 
                    onClick={OpenBookanAppointment} 
                    className="xl:text-lg lg:text-base text-sm font-medium font-display text-violet hover:text-violet-light"
                  >
                    Book an Appointment <span>&gt;</span>
                  </button>
                </div>
              </div>
              <div className="">
              <h2 data-epi-edit="AddressTitle" className="flex font-display font-bold lg:text-lg text-sm lg:mb-3 md:mb-2 md:my-0 my-2">
                <AdressIconn className="w-5 h-5 text-violet mr-2"/>Address
              </h2>
              <div className="xl:text-base lg:text-sm text-xs">
              {Address} <br />
              {Zipcode}
              </div>
              {PhoneNumber
                ? <h2 data-epi-edit="AdressPhone1" className="flex font-display font-normal xl:text-base lg:text-sm text-xs mt-2"><ContactIcon className="w-5 h-5 text-violet mr-1" />{PhoneNumber}</h2>
                : null}
                <div className="lg:mt-3 md:mt-2">
                  <button 
                    onClick={OpenGoogleMaps} 
                    className="xl:text-lg lg:text-base text-sm font-medium font-display text-violet hover:text-violet-light"
                  >
                    Get Directions <span>&gt;</span>
                  </button>
                </div>
               <div className="lg:mt-6 md:mt-4">
              </div>
              </div>
              <div className="">
              <h2 data-epi-edit="WorkingHoursTitle" className="font-display font-bold lg:text-lg text-sm lg:mb-3 md:mb-2 md:mt-0 sm:mt-3 mt-2" >Working Hours</h2>
              {(OpeningHours?.Value?.length > 0) && (
              <div className="font-body">
                {OpeningHours?.Value?.length > 0 && OpeningHours.Value.map((text) => <p key={text} className="xl:text-base lg:text-sm text-xs md:my-0 my-2">{text}</p>)}
              </div>
              )}
              </div>
              <div className="">
              <h2 data-epi-edit="DriveThruTitle" className="font-display font-bold lg:text-lg text-sm lg:mb-3 md:mb-2 md:my-0 my-2">
                Drive-Thru
              </h2>
              <div className="font-body">
                {DrivingHours?.Value?.length > 0 && DrivingHours.Value.map((text) => <p key={text} className="xl:text-base lg:text-sm text-xs md:my-0 my-2">{text}</p>)}
              </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(LocationDetailsCard)
